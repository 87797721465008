import { createStore } from "vuex";
import { t } from "@/i18n";

import axiosInstance from "@/shared/config/easyaxios";

import theme from "@/shared/config/themes";
import modals from "./modal.store";
import alert from "./alert.store";
import project from "./project.store";
import { languageService } from "@/shared/services/language.service";
import { previewLanguageService } from "@/shared/services/preview-language.service";
import { loginService } from "@/auth/service/login.service";
import { sharedRestCallsService } from "@/shared/services/shared-rest-calls.service";
import { EventBus } from "@/shared/js/bus";
import { localStorageService, STORAGE_KEY } from "@/shared/services/local-storage.service";
import { copy } from "@/shared/js/string";

import { makeLogger } from "@/shared/consola";

import { isDevEnvironment, isStagingEnvironment } from "@/environments";

// eslint-disable-next-line no-unused-vars
const logger = makeLogger("store");

const portOrLinkUrl = (globalUrl) => (name) => {
    const link = isDevEnvironment ? `${window.location.origin}/${name}` : `${globalUrl}/${name}`;
    return { [name]: link };
};

export default createStore({
    state: () => ({
        activeApp: "",
        user: {},
        userImpSession: {},
        view: "",
        size: "desktop",
        modal: {},
        waiting: false,
        autoSaveMode: true,
        changesTime: Date(),
        alerts: [],
        errors: [],
        notifies: [],
        googleFonts: [],
        lang: languageService.fallbackLanguage,
        previewLang: previewLanguageService.fallbackLanguage,
        langs: languageService.availableLanguages,
        imgUrl: process.env.VUE_APP_ORIGIN,
        globalUrl: process.env.VUE_APP_ORIGIN,
        showCreator: false,
    }),
    getters: {
        // warn: this is still used in themes.js! for presetThemesService pass
        $l: (state) => (payload) => {
            return payload[state.lang];
        },
        easyCartUrl: () => {
            return isDevEnvironment
                ? "https://easycart.test/"
                : isStagingEnvironment
                  ? "https://dev.easycart.pl/"
                  : "https://app.easycart.pl/";
        },
        easyUrls: (state, getters) => {
            const { globalUrl } = state;
            const makeVariant = portOrLinkUrl(globalUrl);
            const isOnDevOrImpersonating = isDevEnvironment || getters.isImp;
            const cartUrl = isOnDevOrImpersonating
                ? "https://easycart.test/"
                : isStagingEnvironment
                  ? "https://dev.easycart.pl/"
                  : "https://app.easycart.pl/";
            return {
                base: globalUrl,
                cart: cartUrl,
                lms: "https://www.easylms.pl/",
                ...makeVariant("all"),
                ...makeVariant("auth"),
                ...makeVariant("banner"),
                ...makeVariant("coffee"),
                ...makeVariant("cookie"),
                ...makeVariant("faq"),
                ...makeVariant("legal"),
                ...makeVariant("love"),
                ...makeVariant("page"),
                ...makeVariant("player"),
                ...makeVariant("pricing"),
                ...makeVariant("ticker"),
                ...makeVariant("timer"),
            };
        },
        // cancel in handleErrorNotAnOwner:
        appUrl: (state, getters) => {
            return getters.easyUrls[state.activeApp];
        },
        getActiveFaqs: (state) => {
            return state[state.activeApp].faqs;
        },
        iframeLoaded: (state) => {
            return state[state.activeApp].iframeLoaded;
        },
        activeAppHasChanges: (state) => {
            return state[state.activeApp]?.hasChanges || false;
        },
        activeAppDashboardLink: (state, getters) => {
            const isOnDevOrImpersonating = isDevEnvironment || getters.isImp;
            logger.debug("isDevEnvironment", isDevEnvironment, "isImp", getters.isImp);
            logger.debug("isOnDevOrImpersonating", isOnDevOrImpersonating);
            return (
                (isOnDevOrImpersonating ? "" : getters.easyUrls.cart) +
                ({
                    coffee: isOnDevOrImpersonating ? "coffee/projects-list" : "creator/coffees",
                    faq: isOnDevOrImpersonating ? "faq/projects-list" : "creator/faqs",
                    love: isOnDevOrImpersonating ? "love/projects-list" : "creator/testimonials",
                    page: isOnDevOrImpersonating ? "page/projects-list" : "creator/pages",
                    player: isOnDevOrImpersonating ? "player/projects-list" : "creator/players",
                    pricing: isOnDevOrImpersonating ? "pricing/projects-list" : "creator/pricings",
                    timer: isOnDevOrImpersonating ? "timer/projects-list" : "creator/timers",
                    cookie: isOnDevOrImpersonating ? "cookie/projects-list" : "creator/cookies",
                    legal: isOnDevOrImpersonating ? "legal/projects-list" : "creator/legals",
                    banner: isOnDevOrImpersonating ? "banner/projects-list" : "creator/banners",
                    ticker: isOnDevOrImpersonating ? "ticker/projects-list" : "creator/tickers",
                }[state.activeApp] || "creator")
            );
        },
        settingsLink: (_, getters) => `${getters.easyUrls.cart}customer/account`,
        brandingLink: (_, getters) => `${getters.easyUrls.cart}creator/store-settings/branding`,
        userId: (state) => state?.user?.id,
        isPublished: () => (project) => project.access === "published",
        accessLabel: () => (project) => {
            switch (project.access) {
                case "published":
                    return t("shared.public");
                case "draft":
                    return t("shared.draft");
            }
        },
        logged: (state) => Boolean(state.user.email), //bad naming (to refactor)
        isUserPresent: (state) => Boolean(state.user?.id),
        isUserRegistered: (state) => Boolean(state.user?.email),
        isAdmin: (state) => state?.user?.roles?.includes("admin"),
        isImp: (state) => Object.keys(state?.userImpSession).length > 0,
    },
    mutations: {
        setUser(state, payload) {
            if (!payload) {
                return;
            }
            state.user = payload;
            const { id } = state.user;
            if (id) {
                localStorageService.saveValue(STORAGE_KEY.userId, id);
                EventBus.$emit("userReady", state.user);
                languageService.updateBasedOnBackEnd(state.user);
            }
        },
        showNotify(state, payload) {
            state.notifies.push(payload);
            setTimeout(() => {
                state.notifies.splice(0, 1);
            }, 2000);
        },
        setLanguage(state, payload) {
            if (payload) {
                state.lang = payload;
                languageService.setLanguageInLocalStorageAndErrorLang(payload);
            }
        },
        setPreviewLanguage(state, payload) {
            if (payload) {
                state.previewLang = payload;
            }
        },
        showAlert(state, payload) {
            state.alerts.push(payload);
            setTimeout(() => {
                state.alerts.splice(0, 1);
            }, 5000);
        },
        closeAlert(state, payload) {
            state.alerts.splice(payload, 1);
        },
        clearErrors(state) {
            state.errors = [];
        },
        setError(state, payload) {
            state.errors.push(payload);
        },
        updateChangesTime(state) {
            state.changesTime = Date.now();
        },
        toggleAutoSaveMode(state) {
            state.autoSaveMode = !state.autoSaveMode;
        },
        setSize(state, payload) {
            state.size = payload;
        },
        setView(state, payload) {
            state.view = payload;
        },
        showModal(state, payload) {
            state.modal = payload;
        },
        closeModal(state) {
            state.modal = {};
        },
        setFonts(state, payload) {
            state.googleFonts = payload.fonts;
        },
        setPublished(state, payload) {
            payload.access = "published";
        },
        setUnpublished(state, payload) {
            payload.access = "draft";
        },
        showUnloggedClickModal({ state, commit, getters, dispatch }) {
            if (getters.logged) {
                return;
            }
            commit("showModal", {
                type: "info",
                title: t("shared.unlogged_modal.title"),
                desc: t("shared.unlogged_modal.description"),
                cancelBtn: t("shared.common_unlogged_actions.cancel"),
                actionBtn: t("shared.common_unlogged_actions.log_me_in"),
                actionBtn2: t("shared.common_unlogged_actions.register_me"),
                callback: () => {
                    window.location.href = loginService.generateLogInLink(state.lang);
                },
                callback2: () => {
                    if (state.lang === "pl") {
                        dispatch("registerWithEasyCart");
                    } else {
                        window.location.href = loginService.generateRegisterLink(state.lang);
                    }
                },
            });
        },
    },
    actions: {
        toggleCreator({ state }, payload) {
            state.showCreator = payload;
        },
        // App related actions:
        setActiveApp({ state }, payload) {
            state.activeApp = payload;
        },
        getErrors({ state, commit }) {
            return new Promise((resolve) => {
                commit("clearErrors");

                EventBus.$emit("validate", "data");
                EventBus.$nextTick(() => {
                    if (state.errors.length) {
                        commit("showAlert", {
                            type: "wrong",
                            title: t("shared.in_data_errors"),
                            points: state.errors,
                        });
                    }
                    resolve(state.errors.length);
                });
            });
        },
        autosave({ state, commit, dispatch }) {
            commit("updateChangesTime");

            setTimeout(() => {
                if (state.autoSaveMode) {
                    dispatch("saveProject");
                }
            }, 300000);
        },
        async unpublishProject({ state, commit, dispatch }, project) {
            await dispatch(`${state.activeApp}/unpublish`, { uuid: project.uuid });
            commit("setUnpublished", project);
            commit("showAlert", {
                type: "success",
                title: t("shared.project.unpublished"),
            });
        },
        buyPro({ rootState, commit }, { tool = undefined, action = undefined } = {}) {
            const modalData = {
                type: "trial",
                tool: tool ? tool : rootState.activeApp,
                action: action ? action : "buy",
            };
            commit("showModal", modalData);
        },

        // Fonts
        getFonts({ state, commit }) {
            if (state.googleFonts.length) return;
            sharedRestCallsService.getFonts().then(({ items }) => {
                commit("setFonts", {
                    fonts: items
                        .filter((x) => x.subsets.indexOf("latin-ext") > -1)
                        // .filter((y) => y.category == "sans-serif")
                        .map((z) => z.family),
                });
            });
        },
        setFontFamily({ state, commit, dispatch }, payload) {
            dispatch("changeFontFamily", payload);

            if (!payload) {
                return;
            }

            const fontNameNoSpaces = payload.replaceAll(" ", "+");
            const link = document.createElement("link");
            link.setAttribute("rel", "stylesheet");
            link.setAttribute("type", "text/css");
            link.setAttribute(
                "href",
                `https://fonts.googleapis.com/css?family=${fontNameNoSpaces}:300,400,500,600,700,800`
            );
            setTimeout(() => {
                state[state.activeApp].iframe &&
                    state[state.activeApp].iframe.contentDocument.head.appendChild(link);
            }, 100);

            link.onload = () => {
                commit(`${state.activeApp}/updateIframe`);
            };
        },
        changeFontFamily({ state, dispatch }, payload) {
            return dispatch(`${state.activeApp}/changeFontFamily`, payload);
        },
        setIframeLoaded({ state, commit, dispatch }) {
            commit(`${state.activeApp}/setIframeLoaded`);
            dispatch(`${state.activeApp}/refreshIframe`);
        },
        showServerError({ rootState, commit }, payload) {
            commit("showAlert", {
                type: "wrong",
                title: t("shared.server_error"),
                text: payload,
            });
            rootState.waiting = false;
        },

        // miscellaneous:
        download({ dispatch }, { data, filename, type }) {
            var file = new Blob([data], { type: type });
            if (window.navigator.msSaveOrOpenBlob)
                // IE10+
                window.navigator.msSaveOrOpenBlob(file, filename);
            else {
                // Others
                const a = document.createElement("a"),
                    url = URL.createObjectURL(file);
                a.href = url;
                a.download = filename;
                document.body.appendChild(a);
                a.click();
                setTimeout(() => {
                    document.body.removeChild(a);
                    window.URL.revokeObjectURL(url);
                }, 0);
            }
            //fixme
            dispatch("setIframeLoaded");
        },
        generateShortLink({ getters, commit, dispatch }, url) {
            if (!getters.logged) {
                dispatch("showUnloggedClickModal");
                return;
            }
            sharedRestCallsService
                .makeShortLink(url)
                .then((data) => {
                    copy(data);
                    commit("showAlert", {
                        type: "success",
                        title: t("shared.link_url_copied", { url: data }),
                    });
                })
                .catch((error) => dispatch("showServerError", error));
        },

        // User related actions:
        showUnloggedModal({ commit, getters, dispatch, rootState }) {
            if (getters.logged) {
                return;
            }
            commit("showModal", {
                type: "info",
                title: t("shared.dont_loose_progress_modal.title"),
                desc: t("shared.dont_loose_progress_modal.description"),
                cancelBtn: t("shared.common_unlogged_actions.cancel"),
                actionBtn: t("shared.common_unlogged_actions.log_me_in"),
                actionBtn2: t("shared.common_unlogged_actions.register_me"),
                callback: () => {
                    window.location.href = loginService.generateLogInLink(rootState.lang);
                },
                callback2: () => {
                    if (rootState.lang === "pl") {
                        dispatch("registerWithEasyCart");
                    } else {
                        window.location.href = loginService.generateRegisterLink(rootState.lang);
                    }
                },
            });
        },
        showUnloggedClickModal({ commit, getters, dispatch, rootState }) {
            if (getters.logged) {
                return;
            }
            commit("showModal", {
                type: "info",
                title: t("shared.unlogged_modal.title"),
                desc: t("shared.unlogged_modal.description"),
                cancelBtn: t("shared.common_unlogged_actions.cancel"),
                actionBtn: t("shared.common_unlogged_actions.log_me_in"),
                actionBtn2: t("shared.common_unlogged_actions.register_me"),
                callback: () => {
                    window.location.href = loginService.generateLogInLink(rootState.lang);
                },
                callback2: () => {
                    if (rootState.lang === "pl") {
                        dispatch("registerWithEasyCart");
                    } else {
                        window.location.href = loginService.generateRegisterLink(rootState.lang);
                    }
                },
            });
        },
        trackInactivity({ state, getters, dispatch }) {
            if (getters.logged) {
                return;
            }
            var time;
            resetTimer();
            document.onmousemove = resetTimer;
            document.onkeydown = resetTimer;

            function resetTimer() {
                clearTimeout(time);
                time = setTimeout(() => {
                    if (!state.modal.type) {
                        dispatch("showUnloggedModal");
                    }
                }, 120000);
            }
        },
        updateLanguage({ state, commit, dispatch }, payload) {
            state.waiting = true;

            return axiosInstance
                .patch(`/users/${state.user.id}/language`, {
                    language: payload,
                })
                .then(({ data }) => {
                    state.waiting = false;
                    this.commit("setLanguage", payload, { root: true });
                    if (data.message === "Language changed") {
                        commit("showAlert", {
                            type: "success",
                            title: t("shared.language.saved"),
                        });
                    }
                })
                .catch((error) => dispatch("showServerError", error));
        },
        assureUserReady({ state }, callbackFn) {
            if (state.user.id) {
                callbackFn(state.user);
            } else {
                EventBus.$on("userReady", callbackFn);
            }
        },
        async registerWithEasyCart({ rootState: { lang } }) {
            window.location.href = loginService.generateRegisterLink(lang);
        },
        async logout({ rootState, rootGetters }) {
            if (rootGetters.isImp) {
                await loginService.logOutLocally();
                window.location.href = loginService.generateLogInLink(rootState.lang);
            } else {
                await loginService.logOut(rootState.lang);
            }
        },
        async getUser({ state, commit, dispatch } /*, userId*/) {
            let userId;
            try {
                const {
                    data: { id, isRegistered, impSession },
                } = await axiosInstance.get("/auth/identity");
                userId = id;
                if (!isRegistered) {
                    dispatch("trackInactivity");
                }
                if (impSession) {
                    logger.trace("Impersonation should start, impSession updated in store.");
                    state.userImpSession = impSession;
                }
            } catch {
                console.warn("Unable to identify.");
            }
            if (!userId) {
                return dispatch("registerUser");
            }
            try {
                const { data: user } = await axiosInstance.get(`/users/${userId}`);
                commit("setUser", user);
            } catch (e) {
                dispatch("showServerError", e);
            }
        },
        assureUserPresent({ rootGetters }) {
            return new Promise((resolve) => {
                setTimeout(() => {
                    resolve(rootGetters["isUserPresent"]);
                }, 500);
            });
        },
        async registerUser({ commit, dispatch }) {
            try {
                const { data: user } = await axiosInstance.post(`/auth/register-quick`);
                commit("setUser", user);
            } catch (e) {
                dispatch("showServerError", e);
            }
        },
        async mergeUser({ commit, dispatch }, { userId, oldUserId }) {
            try {
                const { data: user } = await axiosInstance.post(`/users/${userId}/merge`, {
                    oldUserId,
                });
                commit("setUser", user);
            } catch (e) {
                dispatch("showServerError", e);
            }
        },
        removeAccount({ state, commit, dispatch }) {
            axiosInstance
                .delete(`/users/${state.user.id}`)
                .then(({ data }) => {
                    if (data.easyCartRedirect) {
                        commit("showAlert", {
                            type: "warning",
                            title: t("cart.redirect"),
                        });
                        setTimeout(() => {
                            window.location.href = data.easyCartRedirect;
                        }, 1000);

                        return false;
                    }

                    commit("showAlert", {
                        type: "success",
                        title: t("cart.account_deleted"),
                    });
                    state.waiting = false;
                    setTimeout(() => {
                        window.location.href = "https://easytools.pl";
                    }, 1000);
                })
                .catch((error) => dispatch("showServerError", error));
        },
        changeEmail({ state, commit }, payload) {
            state.waiting = true;

            axiosInstance
                .patch(`/users/${state.user.id}/email`, {
                    email: payload,
                })
                .then(({ data }) => {
                    if (data.easyCartRedirect) {
                        commit("showAlert", {
                            type: "warning",
                            title: t("cart.redirect"),
                        });
                        setTimeout(() => {
                            window.location.href = data.easyCartRedirect;
                        }, 1000);

                        return false;
                    }

                    state.waiting = false;
                    state.user.email = payload;

                    commit("showAlert", {
                        type: "success",
                        title: t("shared.email_address_saved"),
                    });
                })
                .catch((error) => {
                    commit("showAlert", {
                        type: "wrong",
                        title: error.response.data.message,
                    });
                    state.waiting = false;
                });
        },
    },
    modules: {
        theme,
        modals,
        alert,
        project,
        // @Business: all modules per app are registered dynamically per *App.vue global component.
    },
});
