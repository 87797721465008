import { makeLogger } from "@/shared/consola";

const logger = makeLogger("love.router");

import(/* webpackChunkName: "love_app" */ "./love.route.navigate.js");

const LoveApp = () => import(/* webpackChunkName: "love_app" */ "./LoveApp.vue");
const LoveProjectPage = () =>
    import(/* webpackChunkName: "love_app" */ "./pages/LoveProjectPage.vue");
const LoveListPage = () => import(/* webpackChunkName: "love_app" */ "./pages/LoveListPage.vue");

// Sidebar menus:
const SidebarForm = () =>
    import(/* webpackChunkName: "love_app" */ "@/apps/love/project/sidebar/SidebarForm.vue");
const SidebarGoogleReviews = () =>
    import(
        /* webpackChunkName: "love_app" */ "@/apps/love/project/sidebar/SidebarGoogleReviews.vue"
    );
const SidebarRequestReviews = () =>
    import(
        /* webpackChunkName: "love_app" */ "@/apps/love/project/sidebar/SidebarRequestReviews.vue"
    );
const SidebarReviewsList = () =>
    import(/* webpackChunkName: "love_app" */ "@/apps/love/project/sidebar/SidebarReviewsList.vue");
const SidebarWallStyles = () =>
    import(/* webpackChunkName: "love_app" */ "@/apps/love/project/sidebar/SidebarWallStyles.vue");
const SidebarFormStyles = () =>
    import(/* webpackChunkName: "love_app" */ "@/apps/love/project/sidebar/SidebarFormStyles.vue");
const SidebarWall = () =>
    import(/* webpackChunkName: "love_app" */ "@/apps/love/project/sidebar/SidebarWall.vue");

import { RENDER_ROUTE_NAMES } from "@/views/render/render.routes";
import { testimonialService } from "@/apps/love/services/testimonial.service";

export const LOVE_ROUTE_NAMES = {
    project: "love-project",
    projectForm: "love-project-form",
    projectGoogleReviews: "love-project-google-reviews",
    projectRequestReview: "love-project-request-review",
    projectReviewsList: "love-project-reviews-list",
    projectWallStyles: "love-project-wall-styles",
    projectFormStyles: "love-project-form-styles",
    projectWall: "love-project-wall",
    list: "love-list",
};

export const LOVE_PREVIEW_KIND = {
    wall: "wall",
    form: "form",
    confirm: "confirm",
    mail: "mail",
};

export const LOVE_FIRST_SEGMENT = "love";

export const loveRoutes = [
    {
        path: `/${LOVE_FIRST_SEGMENT}`,
        component: LoveApp,
        children: [
            {
                path: "projects/:projectUuid/:openedSidebar?",
                name: LOVE_ROUTE_NAMES.project,
                component: LoveProjectPage,
                children: [
                    {
                        path: "review-google",
                        name: LOVE_ROUTE_NAMES.projectGoogleReviews,
                        component: SidebarGoogleReviews,
                        meta: { menuCategory: "reviews" },
                    },
                    {
                        path: "review-request",
                        name: LOVE_ROUTE_NAMES.projectRequestReview,
                        component: SidebarRequestReviews,
                        meta: { menuCategory: "reviews" },
                    },
                    {
                        path: "review-list",
                        name: LOVE_ROUTE_NAMES.projectReviewsList,
                        component: SidebarReviewsList,
                        meta: { menuCategory: "reviews" },
                    },
                    {
                        path: "wall",
                        name: LOVE_ROUTE_NAMES.projectWall,
                        component: SidebarWall,
                    },
                    {
                        path: "form-ask-review",
                        name: LOVE_ROUTE_NAMES.projectForm,
                        component: SidebarForm,
                    },
                    {
                        path: "wall-styles",
                        name: LOVE_ROUTE_NAMES.projectWallStyles,
                        component: SidebarWallStyles,
                        meta: { menuCategory: "styles" },
                    },
                    {
                        path: "form-styles",
                        name: LOVE_ROUTE_NAMES.projectFormStyles,
                        component: SidebarFormStyles,
                        meta: { menuCategory: "styles" },
                    },
                    // REDIRECT:
                    {
                        path: ":notFound(.*)?",
                        redirect: ({ params }) => {
                            const { projectUuid, openedSidebar } = params;
                            logger.trace(
                                "Not found sidebar path",
                                `/${openedSidebar}/`,
                                "redirecting to named:",
                                LOVE_ROUTE_NAMES.projectReviewsList
                            );
                            return {
                                name: LOVE_ROUTE_NAMES.projectReviewsList,
                                params: { projectUuid }, // this is CRUCIAL (otherwise notFound will replace :openedSidebar)
                            };
                        },
                    },
                ],
            },
            {
                path: "projects-list",
                name: LOVE_ROUTE_NAMES.list,
                component: LoveListPage,
            },
            {
                path: "create/:projectUuid",
                redirect: ({ params }) => {
                    const { projectUuid } = params;
                    return { name: RENDER_ROUTE_NAMES.loveForm, params: { projectUuid } };
                },
            },
            {
                path: "ask/:projectUuid",
                redirect: ({ params }) => {
                    const { projectUuid } = params;
                    return { name: RENDER_ROUTE_NAMES.loveForm, params: { projectUuid } };
                },
            },
            {
                path: "confirm/:reviewUuid",
                async beforeEnter({ params = {} }) {
                    const { reviewUuid } = params;

                    const { uuid: projectUuid } =
                        await testimonialService.loadFirstWallByTestimonialUuid(reviewUuid);

                    return {
                        name: RENDER_ROUTE_NAMES.loveReviewConfirm,
                        params: { reviewUuid, projectUuid },
                    };
                },
                // @warn: this is crucial otherwise beforeEnter won't fire (there is no point if there is no component).
                component: () => {},
            },
        ],
    },
];
