import { unwrapPaginatedResponse } from "./service.helpers";
import { ajaxCall } from "@/shared/config/ajax-call";
import {
    handleErrorByMessage,
    handleErrorUuidTaken,
    handleErrorNotAnOwner,
} from "@/shared/errors/with-store-error-handlers";

const PROJECT_ENDPOINT = `/projects`;

export const projectsService = {
    getAllForUser({ tool, page, size } = {}) {
        return ajaxCall
            .get(`${PROJECT_ENDPOINT}/${tool}`, { params: { page, size } })
            .then(unwrapPaginatedResponse)
            .catch(handleErrorByMessage());
    },
    getOne({ tool, uuid }) {
        return ajaxCall.get(`${PROJECT_ENDPOINT}/${tool}/${uuid}`).catch(handleErrorNotAnOwner());
    },
    getOneWithSnapshot({ tool, uuid }) {
        return ajaxCall
            .get(`${PROJECT_ENDPOINT}/${tool}/${uuid}/snapshot`)
            .catch(handleErrorNotAnOwner());
    },
    getOneForRender({ tool, uuid }) {
        return ajaxCall
            .get(`${PROJECT_ENDPOINT}/${tool}/${uuid}/render`)
            .catch(handleErrorByMessage());
    },
    create({ tool, name }) {
        return ajaxCall.post(`${PROJECT_ENDPOINT}/${tool}`, { name }).catch(handleErrorByMessage());
    },
    update({ tool, uuid, data }) {
        return ajaxCall
            .patch(`${PROJECT_ENDPOINT}/${tool}/${uuid}`, data)
            .catch(handleErrorByMessage());
    },
    rename({ tool, uuid, name }) {
        return ajaxCall
            .patch(`${PROJECT_ENDPOINT}/${tool}/${uuid}/name`, { name })
            .catch(handleErrorByMessage());
    },
    remove({ tool, uuid }) {
        return ajaxCall.delete(`${PROJECT_ENDPOINT}/${tool}/${uuid}`).catch(handleErrorByMessage());
    },
    clone({ tool, uuid, name }) {
        return ajaxCall
            .post(`${PROJECT_ENDPOINT}/${tool}/${uuid}/clone`, { name })
            .catch(handleErrorByMessage());
    },
    changeUuid({ tool, uuid, newUuid }) {
        return ajaxCall
            .patch(`${PROJECT_ENDPOINT}/${tool}/${uuid}/uuid`, { newUuid })
            .catch(handleErrorUuidTaken());
    },
    publish({ tool, uuid, publish }) {
        return ajaxCall
            .patch(`${PROJECT_ENDPOINT}/${tool}/${uuid}/publish`, { publish })
            .catch(handleErrorByMessage());
    },
};
