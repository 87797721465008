<template>
    <div class="dark:text-white py-5 p-2 mt-2 border-2 rounded-xl border-red-400">
        {{ errorMessage }}
    </div>
</template>

<script>
export default {
    name: "AuthError",
    props: {
        error: {
            type: Error,
            default: () => ({}),
        },
    },
    methods: {
        mapError(key) {
            const computedKey = key.split(" ").join("_");
            return this.$t(`server.auth_error.${computedKey}`);
        },
    },
    computed: {
        message() {
            if (this.error.isAxiosError) {
                return this.error?.response?.data?.message || this.error?.message;
            }
            return this.error.message;
        },
        errorMessage() {
            return this.mapError(this.message);
        },
    },
};
</script>
