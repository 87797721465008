import { makeLogger } from "@/shared/consola";
import { t } from "@/i18n";

const EXTRACT_RESPONSE = "response";

export class ServerResponseError extends Error {
    lang = ""; // must be empty at start (due to an import concurrency problem)
    status = 0;
    logger = makeLogger(ServerResponseError.name);
    errorToken = ServerResponseError.SPECIAL_ERROR_TOKEN.UNKNOWN_ERROR;

    static SPECIAL_ERROR_TOKEN = {
        UNKNOWN_ERROR: "UNKNOWN_ERROR",
        UUID_TAKEN: "UUID_TAKEN",
        SNAPSHOT_NOT_REQUIRED: "SNAPSHOT_NOT_REQUIRED",
        ERR_ACCESS_PLAN_PUBLISH_LIMIT_REACHED: "ERR_ACCESS_PLAN_PUBLISH_LIMIT_REACHED",
        ERR_ACCESS_PLAN_BELOW_REQUIRED: "ERR_ACCESS_PLAN_BELOW_REQUIRED",
        ERR_EID_CANNOT_CONNECT: "ERR_EID_CANNOT_CONNECT",
        ERR_USER_NOT_AN_OWNER: "ERR_USER_NOT_AN_OWNER",
        ERR_PROJECTS_NOT_AN_OWNER: "ERR_PROJECTS_NOT_AN_OWNER",
        ERR_LOGIN_REQUIRED: "ERR_LOGIN_REQUIRED",
    };

    constructor(baseError) {
        super(baseError.message);
        for (const key of Object.keys(baseError)) {
            this[key] = baseError[key];
            if (key === EXTRACT_RESPONSE) {
                const { status, data, statusText } = baseError[EXTRACT_RESPONSE];
                const { statusCode, message } = data;
                this.errorToken = message || statusText || data;
                this.status = statusCode || status;
            }
        }
        this.logger.trace(
            `Errored response[${ServerResponseError.lang}]:`,
            this.errorToken,
            `(${this.status})`,
            this.translatedMessage
        );
    }

    static setAjaxErrorLanguage(lang) {
        ServerResponseError.lang = lang;
    }

    get translatedMessage() {
        const { errorToken } = this;
        if (typeof errorToken === "string" || errorToken === null) {
            if (!errorToken) {
                this.logger.error("Empty error message from server...");
                return t(`server.error.${ServerResponseError.SPECIAL_ERROR_TOKEN.UNKNOWN_ERROR}`);
            }
            if (errorToken?.startsWith?.("ERR_INT_EASY_ID_connect")) {
                return t(
                    `server.error.${ServerResponseError.SPECIAL_ERROR_TOKEN.ERR_EID_CANNOT_CONNECT}`
                );
            }
            return this.translateSingleEntry(errorToken);
        }
        if (Array.isArray(errorToken)) {
            return errorToken.map(this.translateSingleEntry).join("; ");
        }
        return Object.entries(errorToken || {})
            .flatMap(this.mapErrorObject)
            .join("; ");
    }

    translateSingleEntry(token) {
        return t(`server.error.${token}`);
    }

    mapErrorObject([field, errorValue]) {
        return `${t("server.error.ERR_FIELD")} ${field}: ${this.translateSingleEntry(errorValue)}`;
    }

    static checkPublishLimitReached(error) {
        return (
            error?.errorToken ===
                ServerResponseError.SPECIAL_ERROR_TOKEN.ERR_ACCESS_PLAN_PUBLISH_LIMIT_REACHED ||
            (error?.response && error?.response?.status === 402)
        );
    }
}
