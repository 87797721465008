import { presetThemesService } from "@/shared/services/preset-themes.service";
import { userThemesService } from "@/shared/services/user-themes.service";
import { makeLogger } from "@/shared/consola";
import { t } from "@/i18n";

// eslint-disable-next-line no-unused-vars
const logger = makeLogger("theme.store");

export default {
    namespaced: true,
    state: () => ({
        userThemes: [],
    }),
    getters: {
        stylePresets: () => presetThemesService.getAllStylePresets(),
        colorPresets: () => presetThemesService.getAllColorPresets(),
        colorThemes: (state, getters) => [...state.userThemes, ...getters.colorPresets],
    },
    mutations: {
        setUserThemes(state, payload) {
            state.userThemes = payload;
        },
        addTheme(state, payload) {
            state.userThemes.push(payload);
        },
    },
    actions: {
        async getUserThemes({ rootState, commit }) {
            rootState.waiting = true;
            try {
                const themes = await userThemesService.getAllForUser();
                commit("setUserThemes", themes);
                rootState.waiting = false;
            } catch {
                /* eslint-disable-next-line no-empty */
            }
        },
        async createTheme(
            { rootState, commit },
            { name, font, isDefault, logo, logoSquare, colors }
        ) {
            rootState.waiting = true;

            logger.debug("Try to create theme from", {
                name,
                font,
                isDefault,
                logo,
                logoSquare,
                colors,
            });
            const themeDto = userThemesService.prepareThemeDto(
                name,
                { font, isDefault, logo, logoSquare },
                colors
            );
            logger.debug("Prepared DTO:", themeDto);
            const newTheme = await userThemesService.create(themeDto);

            commit("addTheme", newTheme);
            commit(
                "showAlert",
                {
                    type: "success",
                    title: t("shared.themes.created"),
                },
                { root: true }
            );
            rootState.waiting = false;
            return newTheme;
        },
    },
};
