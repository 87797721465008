import { ajaxCall } from "@/shared/config/ajax-call";
import { handleErrorByMessage } from "@/shared/errors/with-store-error-handlers";

const TESTIMONIALS_ENDPOINT = `/testimonials`;

const arrayToStringOrEmpty = (fieldName, value) => ({
    [fieldName]: Array.isArray(value) && value.length ? JSON.stringify(value) : undefined,
});

export const testimonialService = {
    searchForGifs(text) {
        return ajaxCall.get(`${TESTIMONIALS_ENDPOINT}/search-gifs`, {
            params: { q: text },
        });
    },
    createNew(payload, params = {}) {
        const { gifs, images } = payload || {};
        const testimonial = {
            ...payload,
            ...arrayToStringOrEmpty("images", images),
            ...arrayToStringOrEmpty("gifs", gifs),
        };
        // This will ALSO fire logic to webhooks / send emails - on BACK END
        return ajaxCall
            .post(TESTIMONIALS_ENDPOINT, testimonial, { params })
            .catch(handleErrorByMessage());
    },
    confirmAndUpdateReview(id, review) {
        return testimonialService.update(id, review, true);
    },
    update(id, payload, confirm) {
        const { gifs, images } = payload || {};
        const testimonial = {
            ...payload,
            ...arrayToStringOrEmpty("images", images),
            ...arrayToStringOrEmpty("gifs", gifs),
        };
        return ajaxCall
            .patch(`${TESTIMONIALS_ENDPOINT}/${id}`, testimonial, { params: { confirm } })
            .catch(handleErrorByMessage());
    },
    delete({ id, order, wallId }) {
        return ajaxCall
            .delete(`${TESTIMONIALS_ENDPOINT}/${id}`, {
                data: { order, wallId },
            })
            .catch(handleErrorByMessage());
    },
    loadFirstWallByTestimonialUuid(uuid) {
        return ajaxCall
            .get(`${TESTIMONIALS_ENDPOINT}/wall`, {
                params: {
                    uuid,
                },
            })
            .catch(handleErrorByMessage());
    },
    getOneByUuid(uuid = "") {
        return ajaxCall
            .get(`${TESTIMONIALS_ENDPOINT}/token`, { params: { id: uuid } })
            .catch(handleErrorByMessage());
    },
    sendConfirmationRequest({ id, email, content }) {
        return ajaxCall
            .post(`${TESTIMONIALS_ENDPOINT}/${id}/request`, { email, content })
            .catch(handleErrorByMessage());
    },
    confirm({ id, testimonial }) {
        const { gifs, images } = testimonial || {};
        const computedTestimonial = {
            ...testimonial,
            ...arrayToStringOrEmpty("images", images),
            ...arrayToStringOrEmpty("gifs", gifs),
        };
        return ajaxCall
            .post(`${TESTIMONIALS_ENDPOINT}/${id}/confirm`, computedTestimonial)
            .catch(handleErrorByMessage());
    },
};
